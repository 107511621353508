body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F3F2EE;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
}

span {
  font-weight: normal;
  /*font-family: "Song Myung", serif;*/
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1s ease-out;
}

.description {
  position: absolute;
  bottom: 10px; /* 설명이 위치할 여백을 조정하세요 */
  left: 10px; /* 설명이 위치할 여백을 조정하세요 */
  background-color: rgba(0, 0, 0, 0.5); /* 설명의 배경색과 투명도를 조정하세요 */
  color: white; /* 설명의 글자색을 조정하세요 */
  padding: 5px 10px; /* 설명의 여백을 조정하세요 */
  font-size: 14px; /* 설명의 글자 크기를 조정하세요 */
}

.background-music-container {
  position: fixed;
  top: 15px;
  left: 15px;
  z-index: 999;
}

.music-control {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #333; /* 원하는 색상으로 변경합니다. */
  margin-right: 10px; /* 버튼 사이의 간격을 조정합니다. */
}

.container {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 260px;
  margin-bottom: 10px;
}

.container-1 {
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 260px;
  margin-bottom: 10px;
}

.container-3 {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 260px;
  margin-bottom: 10px;
}

.slider {
  display: flex;
  animation: slide 6s infinite linear;
}

.slider-1 {
  display: flex;
}

.slider-3 {
  display: flex;
  animation: slide-3 6s infinite linear;
}

.item {
  height: 100%;
  margin-right: 10px;
}

.item-1 {
  height: 100%;
}

.item-3 {
  height: 100%;
  margin-right: 10px;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes slide-3 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-65%);
  }
}

.container-one {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 260px;
  margin-bottom: 30px;
}

.slider-one {
  display: flex;
}

.item-one {
  height: 100%;
}
